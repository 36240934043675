.branding-title {
	margin: 0;
	@include font-size(46);
	font-weight: normal;
	line-height: 1em;
}

.logo {
	display: inline-block;
	margin: 0 auto;
	@include font-size(42);
	font-family: $font-family-headings;
	font-weight: $body-weight-normal;
	line-height: 1em;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	color: $color-primary !important;
	small {
		display: block;
		padding: 6px 20px 5px;
		font-family: $font-family-headings;
		@include font-size(16);
		line-height: 1em;
		letter-spacing: 0em;
		background-color: theme(secondary, base);
		color: $white;
	}
}

	.branding-logo {}

	.site-info-logo {}